import { render, staticRenderFns } from "./Free_resources.vue?vue&type=template&id=2e0c44f8&"
import script from "./Free_resources.vue?vue&type=script&lang=js&"
export * from "./Free_resources.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports