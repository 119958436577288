<template>
  <div>
    <!-- search start -->
    <section class="free_resources pt_50">
      <div class="container free-resource">
        <h2 class="heading_2 text-center">Ergo Resources</h2>
        <div class="search_content">
          <div class="row justify-content-center">
            <div class="col-md-12 col-xl-10 col-xxl-12">
              <!-- <div class="input-group">
                        <input class="form-control border" type="search" value="" placeholder="" id="example-search-input">
                        <span class="icon"><img src="../../assets/home/images/free_resources/search.svg" alt=""></span>
                     </div> -->
              <p class="text-center">
                Go for it! Discover what ergo can do for you today. Don’t see
                what your company is looking for? Reach out to the PBE team for
                custom video options.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- search end -->
    <!-- video start -->
    <section class="video-library padding_100">
      <div class="container">
        <div class="row mb_30">
          <h6>Ergo Essentials</h6>
          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              class="text_black"
            >
              <div class="video_box">
                <img
                  src="../../assets/home/images/free_resources/f1.png"
                  alt="img"
                  class="free_img"
                />
                <span>
                  <img
                    src="../../assets/home/images/free_resources/video.svg"
                    alt="img"
                    class="v_icon"
                  />
                  Posture Perfect
                </span>
              </div>
            </a>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
              class="text_black"
            >
              <div class="video_box">
                <img
                  src="../../assets/home/images/free_resources/f2.png"
                  alt="img"
                  class="free_img"
                />
                <span>
                  <img
                    src="../../assets/home/images/free_resources/video.svg"
                    alt="img"
                    class="v_icon"
                  />
                  30 Second Setup
                </span>
              </div>
            </a>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
              class="text_black"
            >
              <div class="video_box">
                <img
                  src="../../assets/home/images/free_resources/f3.png"
                  alt="img"
                  class="free_img"
                />
                <span>
                  <img
                    src="../../assets/home/images/free_resources/video.svg"
                    alt="img"
                    class="v_icon"
                  />
                  Forearm Stretch
                </span>
              </div>
            </a>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal3"
              class="text_black"
            >
              <div class="video_box">
                <img
                  src="../../assets/home/images/free_resources/f4.png"
                  alt="img"
                  class="free_img"
                />
                <span>
                  <img
                    src="../../assets/home/images/free_resources/video.svg"
                    alt="img"
                    class="v_icon"
                  />
                  Energy Exercises
                </span>
              </div>
            </a>
          </div>
        </div>
        <div class="row mb_30">
          <h6>WFH Survival Tips</h6>
          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <a href="#" data-bs-toggle="modal" data-bs-target="#submit_form_and_download"  @click="openForm('wfh_tips')">
              <div class="video_box">
                <img
                  src="../../assets/home/images/free_resources/f5.png"
                  alt="img"
                  class="free_img"
                />
                <span class="d-block text-center"> Work From Home Tips </span>
              </div>
            </a>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <a href="#" data-bs-toggle="modal" data-bs-target="#submit_form_and_download"  @click="openForm('sitstand_guidlines')">
              <div class="video_box">
                <img
                  src="../../assets/home/images/free_resources/f6.png"
                  alt="img"
                  class="free_img"
                />
                <span class="d-block text-center"> Sit/Stand Guidelines </span>
              </div>
            </a>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <a href="#" data-bs-toggle="modal" data-bs-target="#submit_form_and_download"  @click="openForm('zoom_guidlines')">
              <div class="video_box">
                <img
                  src="../../assets/home/images/free_resources/f7.png"
                  alt="img"
                  class="free_img"
                />
                <span class="d-block text-center"> Zoom Guidelines </span>
              </div>
            </a>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <a href="#" data-bs-toggle="modal" data-bs-target="#submit_form_and_download"  @click="openForm('avoiding_burnout')">
              <div class="video_box">
                <img
                  src="../../assets/home/images/free_resources/f8.png"
                  alt="img"
                  class="free_img"
                />
                <span class="d-block text-center"> Avoiding Burnout </span>
              </div>
            </a>
          </div>
        </div>

        <div class="row">
          <h6>Productivity Hacks</h6>
          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <a href="#" data-bs-toggle="modal" data-bs-target="#submit_form_and_download"  @click="openForm('ergo_tips_for_kids')">
              <div class="video_box">
                <img
                  src="../../assets/home/images/free_resources/f9.png"
                  alt="img"
                  class="free_img"
                />
                <span class="d-block text-center"> Ergo Tips for Kids </span>
              </div>
            </a>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <a href="#" data-bs-toggle="modal" data-bs-target="#submit_form_and_download"  @click="openForm('wfh_equipment')">
              <div class="video_box">
                <img
                  src="../../assets/home/images/free_resources/f10.png"
                  alt="img"
                  class="free_img"
                />
                <span class="d-block text-center">
                  Work From Home Equipment
                </span>
              </div>
            </a>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <a href="#" data-bs-toggle="modal" data-bs-target="#submit_form_and_download"  @click="openForm('ergo_typing')">
              <div class="video_box">
                <img
                  src="../../assets/home/images/free_resources/f11.png"
                  alt="img"
                  class="free_img"
                />
                <span class="d-block text-center"> Ergo Typing </span>
              </div>
            </a>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <a href="#" data-bs-toggle="modal" data-bs-target="#submit_form_and_download"  @click="openForm('productivity_hacks')">
              <div class="video_box">
                <img
                  src="../../assets/home/images/free_resources/f12.png"
                  alt="img"
                  class="free_img"
                />
                <span class="d-block text-center"> Productivity Hacks </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <!-- video end -->

    <!-- empower start -->
    <section class="empower_sec pt_46 pb_50 bg_primary">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="empower_box text-center">
              <h5 class="heading_5 mb_30 text_white font_bold">
                Empower your team with Ergo-Ally
              </h5>
              <router-link :to="{ name: 'learn_more' }" class="btn btn_outline"
                >Request an Ergo-Ally Demo</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <div
      class="modal fade meet_our_team_modal free_resources_form"
      id="submit_form_and_download"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form
              class="mt_10 captureData"
              method="post"
              @submit.prevent="downloadForm"
              data-vv-scope="downloadForm"
            >
              <div class="mb-3 form-group position-relative">
                <input
                  type="text"
                  class="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Name"
                  v-validate="'required'"
                  name="full_name"
                  v-model="full_name"
                  data-vv-as="Name"
                />
                <small
                  class="error text-danger"
                  v-show="errors.has('downloadForm.full_name')"
                >
                  {{ errors.first("downloadForm.full_name") }}
                </small>
              </div>
              <div class="mb-3 form-group">
                <input
                  type="email"
                  class="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Work Email"
                  v-validate="'required|email'"
                  v-model="email"
                  name="email"
                  data-vv-as="Work email"
                />
                <small
                  class="error text-danger"
                  v-show="errors.has('downloadForm.email')"
                >
                  {{ errors.first("downloadForm.email") }}
                </small>
              </div>
              <div class="mb-3 form-group">
                <input
                  type="text"
                  class="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Company"
                  v-validate="'required'"
                  name="company_name"
                  v-model="company_name"
                  data-vv-as="Company name"
                />
                <small
                  class="error text-danger"
                  v-show="errors.has('downloadForm.company_name')"
                >
                  {{ errors.first("downloadForm.company_name") }}
                </small>
              </div> 
              <div class="mb-3 form-group">
                <v-select
                  :reduce="(mp_list) => mp_list.id"
                  :options="mp_list"
                  label="name"
                  placeholder="Do you manage a program?"
                  name="manage_program"
                  v-model="manage_program"
                  v-validate="'required'"
                  class="select vs-drop-cust select-free-resource"
                />
                <small
                  class="error text-danger"
                  v-show="errors.has('downloadForm.manage_program')"
                >
                  {{ errors.first("downloadForm.manage_program") }}
                </small>
              </div> 
              <!-- <div class="mb-3 form-group">
                <input
                  type="text"
                  class="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter Contact No."
                  v-validate="'required|integer|min:10|max:12'"
                  name="contact_number"
                  v-model="contact_number"
                  data-vv-as="Contact No."
                />
                <small
                  class="error text-danger"
                  v-show="errors.has('downloadForm.contact_number')"
                >
                  {{ errors.first("downloadForm.contact_number") }}
                </small>
              </div>  -->
              <div class="text-center mt_30">
                <button class="btn btn_primary" type="submit">Download</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> 
    <!-- empower end -->
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade meet_our_team_modal free_resources_form"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="hideModal"
            ></button>
          </div>
          <div class="modal-body">
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                id="closed"
                src="https://player.vimeo.com/video/661824377?h=18efe0e91c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="Sitting Pretty"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade meet_our_team_modal free_resources_form"
      id="exampleModal1"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="hideModal1"
            ></button>
          </div>
          <div class="modal-body">
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                id="closed1"
                src="https://player.vimeo.com/video/580346529?h=3561a2038c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="ERGO_in 30 seconds"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade meet_our_team_modal free_resources_form"
      id="exampleModal2"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="hideModal2"
            ></button>
          </div>
          <div class="modal-body">
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                id="closed2"
                src="https://player.vimeo.com/video/327610621?h=8a272849c4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="Palm, Forearm, Finger Stretch"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade meet_our_team_modal free_resources_form"
      id="exampleModal3"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="hideModal3"
            ></button>
          </div>
          <div class="modal-body">
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                id="closed3"
                src="https://player.vimeo.com/video/320352657?h=424e9f51b8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="Recharge Series"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade meet_our_team_modal free_resources_form"
      id="exampleModal4"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="hideModal3"
            ></button>
          </div>
          <div class="modal-body">
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                id="closed4"
                src="https://player.vimeo.com/video/661822729?h=d9cc045c0e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="Time Management and Productivity Tips (that really work)"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade meet_our_team_modal free_resources_form"
      id="exampleModal5"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                src="https://player.vimeo.com/video/661823396?h=15f2cd13b0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="Your Sit/Stand Desk"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade meet_our_team_modal free_resources_form"
      id="exampleModal6"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                src="https://player.vimeo.com/video/661643703?h=b0b76e1502&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="Your Sit/Stand Desk"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
// import { mapGetters } from "vuex";
import store from "@/store/index";

export default {
  name: "free_resources",
  data() {
    return {
      pdf_for: null,
      full_name: null,
      email: null,
      contact_number: null,
      company_name:null,
      manage_program:null,
      mp_list: [
        
        {
          id: 'Yes',
          name: "Yes",
        }, {
          id: 'No',
          name: "No",
        },
      ],
    };
  },
  methods: {
    hideModal() {
      document.getElementById("closed").src += "";
    },
    hideModal1() {
      document.getElementById("closed1").src += "";
    },
    hideModal2() {
      document.getElementById("closed2").src += "";
    },
    hideModal3() {
      document.getElementById("closed3").src += "";
    },
    hideModal4() {
      document.getElementById("closed4").src += "";
    },
    openForm(pdf_for) { 
      this.$validator.reset();
      this.full_name =  null;
      this.email =  null;
      this.company_name =  null;
      this.manage_program = null;
      this.contact_number =  null;
      this.pdf_for = pdf_for;
    },
    download_file(fileURL, fileName) {
        // for non-IE
        console.log();
        if (!window.ActiveXObject) {
            var save = document.createElement('a');
            save.href = fileURL;
            save.target = '_blank';
            var filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
            save.download = fileName || filename;
            if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
            document.location = save.href; 
          // window event not working here
          }else{
                var evt = new MouseEvent('click', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': false
                });
                save.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
          }	
        }
        // for IE < 11
        else if ( !! window.ActiveXObject && document.execCommand)     {
            var _window = window.open(fileURL, '_blank');
            _window.document.close();
            _window.document.execCommand('SaveAs', true, fileName || fileURL)
            _window.close();
        }
    },
    downloadForm() {      
      this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      this.$validator.validateAll("downloadForm").then((isValid) => {
        if (isValid) {
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/submit-leads",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: {
              pdf_for: this.pdf_for,
              full_name: this.full_name,
              email: this.email,
              company_name: this.company_name,
              manage_program: this.manage_program,
            },
          };
          this.axios(config)
            .then(({ data }) => {
             console.log(data);
              // this.download_file(data.pdf_url,this.pdf_for+".pdf");
              // var fileURL = window.URL.createObjectURL(new Blob([data.pdf_url]));
              var fileURL = data.pdf_url;
              var fileLink = document.createElement("a");
              fileLink.href = fileURL;
              fileLink.target = '_blank';
              fileLink.setAttribute("download", this.pdf_for+".pdf");
              document.body.appendChild(fileLink);
              fileLink.click();

              this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              this.$toast.success(data.message, {
                position: "top-right",
                duration: 5000,
              });
              document.getElementsByClassName('btn-close')[0].click();
            })
            .catch(({ response }) => {
              this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              // if(response.status==500){
                document.getElementsByClassName('btn-close')[0].click();
                this.$toast.error(response.data.error, {
                  position: "top-right",
                  duration: 5000,
                });
              // }
            });
        } else {
          this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          console.log("Error In Validation", this.errors);
          //   this.$parent.isLoader = false;
        }
      });
    }
  },
  mounted() {
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
    store.dispatch("Home/getHomeData").then(() => {});
  },
};
</script>